import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HashLoader as Loader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [formState, setFormState] = useState({ name: "", email: "", text: "" })
  const [notification, setNotification] = useState("")
  const [loading, setLoading] = useState(false)
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const mess = await backend.submitFeedback(formState)
      setNotification("feedback submitted!")
    } catch (err) {
      setNotification(
        "there was a server error trying to process your request. Please try again later"
      )
    }
    setTimeout(() => setLoading(false), 250)
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Feedback" />
      <h4>Submit your feedback</h4>
      {loading ? (
        <Loader />
      ) : (
        <form className="flex-column-start" onSubmit={onSubmit}>
          {notification ? <p>{notification}</p> : ""}
          <input
            type="text"
            placeholder="Name*"
            name="name"
            value={formState.name}
            onChange={onChange}
            required
          />
          <input
            type="email"
            placeholder="Email*"
            name="email"
            value={formState.email}
            onChange={onChange}
            required
          />

          <textarea
            placeholder="Tell us what you think 😊*"
            name="text"
            value={formState.text}
            onChange={onChange}
            required
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
